import { getCharacters } from '../Data/CharactersData';

import { Link } from 'react-router-dom';

const Characters = (props) => {

	const characters = getCharacters();

	return (
		<>
			<h1>Szereplők</h1>
			<ul className="characterList">
				{characters.map((character, i) => {
					return (
						<li>
							{/* <img src={character.image} alt={character.title} /> */}
							<img src={character.image} alt={character.name} />
							<h2>{character.name}</h2>
							
							<span>
								{character.description}
							</span>
						</li>
					);
				})}
			</ul>
		</>
	);
}

export default Characters;
