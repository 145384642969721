const characters = [
	{ 
		"name": "Hangya Henrik",
		"image": "hangya-henrik",
		"description": "Kicsit lusta, kedves, mindenkivel barátságos hangya fiú. Segítőkész, barátai problémáit megpróbálja megoldani, emellett azért szereti az erejét fitogtatni."
	},
	{
		"name": "Mogyoró",
		"image": "mogyoro",
		"description": "A legokosabb, legkedvesebb kutya a világon. A nagyi kertjében mindenképp az. Hűséges társa a gyerekeknek, minden kalandra rávehető, még a kincskeresésre is."
	},
	{
		"name": "Sün Benedek",
		"image": "sun-benedek",
		"description": "Folyamatosan beszél, szereti a saját hangját hallani, ezért ragadt rá a beceneve: Hablaty. Alapvetően kedves, jószívű, de zsörtölődni is szeret."
	},
	{
		"name": "Mókus Móric",
		"image": "moric",
		"description": "Fürge, mozgékony, barátait óvó kedves állat. A nyomozó csapat vidám tagja lesz."
	},
	{
		"name": "Nyuszi",
		"image": "nyuszi",
		"description": "akinek sok neve lesz a történet végére. Hogy miért? Az majd kiderül a könyvből.  Eleinte fellengzősen viselkedik, de ezzel csak a szíve bánatát próbálja leplezni. Bátor, mert hősiesen megmenti a mókust."
	}/* ,
	{
		"name": "Elemér",
		"image": "elemer",
		"description": "Ő egy idős, nyugdíjas egér. Nyugodtan éli az életét, szívesen visszaemlékszik fiatalkori csínyeire."
	},
	{
		"name:": "Leonór",
		"image": "leonor",
		"description": "Neki sok szerepe nincs a történetben, ő a hős egerünk felesége. Figyelmes, idős egér hölgy."
	} */
];

function sanitizeFilename(filename) {
	return filename.replace(/[^a-zA-Z0-9-_]/g, '');
};

function getCharacters() {
	let tempCharacters = JSON.parse(JSON.stringify(characters));
console.log('what: ',tempCharacters);
	for (let i = 0; i < tempCharacters.length; i++) {
		//tempCharacters[i].image = sanitizeFilename(tempCharacters[i].image);
		tempCharacters[i].image = require(`../assets/images/characters/${sanitizeFilename(tempCharacters[i].image)}.png`);
	}

	return tempCharacters;
}

export {getCharacters}
